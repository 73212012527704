.App-logo {
  height: 40vmin;
  pointer-events: none;
}

svg {
  display: inline-block;
  vertical-align: middle;
}


.row {
  display: flex;
  align-items: center;
}

.Custom-header {
  padding-top: 25px;
  padding-bottom:50px;
}

.App-header {
  font-size: 80px;
  font-family: "Helvetica Neue", serif;
  font-weight: lighter;
  /*min-height: 200vh;*/
  display: flex;
  color: black;
}

.App-sub-header {
  font-size: 20px;
  font-family: "Helvetica Neue", serif;
  font-weight:200;
  color: black;
}

.App-link {
  color: #61dafb;
}

.navbar {
  font-family: "Helvetica Neue", serif;
  font-weight:300;
  font-size:20px;
  color: black;
}

.nav-link {
  margin-left: 20px;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

.social-container {
  padding: 25px 50px;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.instagram {
  color: #f09433;
}

a.email {
}

.child {
  padding: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}